$button-save-active: #1351BF;
$button-save-inactive: #8D8D8D;

$mat-icon-collor-blue:  #1351bf;

$button-text-color-blue:  #1351BF;

$title-page-collor: #000000DE;
$info-page-collor: #161616;
$description-page-collor: #525252;

$msg-error-valid: green;
$msg-error-invalid: #ff0000;

$tooltip-alert-color: #b35252;

$alert-color: #E88600;
$error-color: #B00020;
$success-color: #52BF05;

$background-color-login: #f3f3f3;
$background-th-table-color: #E6EAF7; 
$background-div-table-color: #F4F4F4;
$font-white-color: #FFFFFF;