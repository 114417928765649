/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family:  Open Sans, sans-serif }

@import '~bootstrap/dist/css/bootstrap.min.css';
@import './assets/styles/colors.scss';
@import './assets/styles/fonts.scss';

h2 {
  font-weight: 300 !important;
  font-family: Open Sans, sans-serif !important;
}

.example-tooltip-red {
  background: $tooltip-alert-color;
}

p {
  font-family: Open Sans, sans-serif;
}

.title-fixed {
  background-color:  #F4F4F4;
  padding-top: 16px;
}

.valido{
  color: $msg-error-valid;
}

.invalido {
  color: $msg-error-invalid;
}

#lblObrigatorio {
  color: $msg-error-invalid;
  display:inline;
  margin-left: 0px;
  font-size: 12px;
}

.styled-scrollbar {
  &::-webkit-scrollbar {
    width: 6px !important;
    background-color: lighten(#555, 55%) !important;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(50, 50, 50, .3);
    background-color: #555;
  }
}

.save-button-active {
    display: block;
    position: sticky;
    background-color: $button-save-active;
    color: #fff;
    bottom: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 14px;
    border: none;
    width: 100%;
    padding: .5em;
    opacity: 1;
  }

  .save-button-inactive {
    position: fixed;
    bottom: 0;
    margin-top: 60px;
    margin-left: -150px;
    width: 102%;
    opacity: 1;
    color: white;
    background: $button-save-inactive;
    height: 48px;
  }

  .large-width {
    max-width: 516px;
    margin-right: 16px;
  }

  .medium-width {
    max-width: 250px;
    margin-right: 16px;
  }

  .small-width {
    max-width: 100px;
    margin-right: 16px;
  }


  @media screen and (max-width: 800px){
    .save-button-active {
      margin-left: -10px;
    }
  
    .save-button-inactive {
      margin-left: -10px;
    }
  }
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
