@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./../fonts/OpenSans-Light.ttf');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./../fonts/OpenSans-Regular.ttf');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./../fonts/OpenSans-SemiBold.ttf');
}

$font-login-span:'MyriadPro-Regular',  sans-serif;
$padding-font-page-initial: 30px 0px 0px 0px;

$title-font-size: 36px;
$title-font-size-mobile: 34px;

$sub-title-font-size: 24px;
$sub-title-font-size-mobile: 16px;

$resume-tittle-font-size: 13px;
$resume-tittle-font-size-mobile: 10px;

$content-text-table-size: 13px;
$content-text-table-size-mobile: 9px;

$icon-size-small: 16px;
$icon-size-medium: 24px;
$icon-size-large: 32px;


$font-family-page: 'Open Sans', sans-serif;


